import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { hardNavigateMiddleware } from '@presentation/shared/router';
import { ORDERS_URL } from 'app/presentation/shared/constants';
import { CancelOrderDialogComponent } from './cancel-order-dialog/cancel-order-dialog.component';
import { ChildOrderItemDialogComponent } from './child-order-item-dialog/child-order-item-dialog.component';
import { ChildOrderOverviewComponent } from './child-order-overview/child-order-overview.component';
import { GlobalOrderPreferencesDialogComponent } from './global-order-preferences-dialog/global-order-preferences-dialog.component';
import { OrderCompletionComponent } from './order-completion/order-completion.component';
import { OrderFiltersComponent } from './order-filters/order-filters.component';
import { OrderItemDialogComponent } from './order-item-dialog/order-item-dialog.component';
import { OrderRefundsComponent } from './order-refunds/order-refunds.component';
import { OrderReplacementsComponent } from './order-replacements/order-replacements.component';
import { OrderTrackingDialogComponent } from './order-tracking-dialog/track-orders-dialog.component';
import { OrdersComponent } from './orders.component';
import { RatingBarComponent } from './rating-bar/rating-bar.component';
import { RefundsPolicyDialogComponent } from './refunds-policy-dialog/refunds-policy-dialog.component';
import { TrackOrdersDialogComponent } from './track-orders-dialog/track-orders-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    CancelOrderDialogComponent,
    ChildOrderItemDialogComponent,
    OrderCompletionComponent,
    OrderItemDialogComponent,
    OrderRefundsComponent,
    OrderReplacementsComponent,
    RatingBarComponent,
    RefundsPolicyDialogComponent,
    TrackOrdersDialogComponent,
    OrdersComponent,
    ChildOrderOverviewComponent,
    OrderFiltersComponent,
    GlobalOrderPreferencesDialogComponent,
    OrderTrackingDialogComponent,
  ],
  providers: [
    provideRouter([
      { path: ':taagerId/:orderId', component: OrdersComponent },
      {
        path: '',
        component: OrdersComponent,
        canActivate: [hardNavigateMiddleware(ORDERS_URL)],
      },
    ]),
  ],
  declarations: [],
})
export class OrdersModule {}
